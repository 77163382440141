<div
  matDialogTitle
  class="flex justify-between items-center">Kostenstelle bearbeiten
    <button
      mat-icon-button
      mat-dialog-close
      matTooltip="Abbrechen"
      class="close-button">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content>
    <div class="flex flex-col gap-2">
        <div class="mat-typography max-w-72">
            Bearbeiten Sie die Kostenstelle für den Artikel
            <b>{{ productName }}</b>.
        </div>

        <div class="dialog-container mat-typography flex flex-col">
            <form [formGroup]="costCenterForm">
                <dqn-form-field class="w-full">
                    <dqn-label>Kostenstelle</dqn-label>

                    <dqn-combobox
                      formControlName="costCenter"
                      [options]="availableCostCentersAsDqnOptions" />
                </dqn-form-field>
            </form>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button
      mat-button
      color="accent"
      (click)="cancel()">
        Abbrechen
    </button>
    <button
      mat-flat-button
      color="primary"
      (click)="save()">
        Speichern
    </button>
</mat-dialog-actions>
