import {SharedModule as GlobalSharedModule} from '@/shared';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DqnComboboxModule} from '@dqn/components/combobox';
import {DqnFormFieldModule} from '@dqn/components/form-field';
import {DqnTableModule} from '@dqn/components/table';
import {ProductUnitDisplayTextPipe} from '../shared/pipes/product-unit-display-text.pipe';
import {
  CostCenterEditDialogComponent
} from './components/dialogs/cost-center-edit-dialog/cost-center-edit-dialog.component';
import {
  IsVariantOptionSelectablePipe
} from './components/product-variant-selection/pipes/is-variant-option-selectable.pipe';
import {
  IsVariantOptionSelectedPipe
} from './components/product-variant-selection/pipes/is-variant-option-selected.pipe';
import {
  ProductVariantSelectionComponent
} from './components/product-variant-selection/product-variant-selection.component';
import {
  SupplierProductPriceComponent
} from './components/supplier-products-product-list/components/supplier-product-price/supplier-product-price.component';
import {
  SupplierProductsProductListActionsDirective
} from './components/supplier-products-product-list/directives/supplier-products-product-list-actions.directive';
import {
  SupplierProductsProductListComponent
} from './components/supplier-products-product-list/supplier-products-product-list.component';
import {ProductAttributeTemplateByIdPipe} from './pipes/product-attribute-template-by-id.pipe';
import {SupplierProductDetailsRouterLinkPipe} from './pipes/supplier-product-details-router-link.pipe';
import {SupplierProductsForProductPipe} from './pipes/supplier-products-for-product.pipe';

@NgModule({
  imports: [
    CommonModule,
    GlobalSharedModule,
    DqnComboboxModule,
    DqnFormFieldModule,
    DqnTableModule,
  ],
  declarations: [
    ProductUnitDisplayTextPipe,
    CostCenterEditDialogComponent,
    SupplierProductsProductListComponent,
    SupplierProductPriceComponent,
    SupplierProductDetailsRouterLinkPipe,
    ProductVariantSelectionComponent,
    IsVariantOptionSelectedPipe,
    IsVariantOptionSelectablePipe,
    ProductAttributeTemplateByIdPipe,
    SupplierProductsForProductPipe,
    SupplierProductsProductListActionsDirective,
  ],
  exports: [
    ProductUnitDisplayTextPipe,
    CostCenterEditDialogComponent,
    SupplierProductsProductListComponent,
    SupplierProductPriceComponent,
    SupplierProductDetailsRouterLinkPipe,
    ProductVariantSelectionComponent,
    ProductAttributeTemplateByIdPipe,
    SupplierProductsForProductPipe,
    SupplierProductsProductListActionsDirective,
  ],
})
export class SharedModule {
}
