import {convertCostCenterToDqnOption} from '@/shared/state/cost-centers/helpers/convert-cost-center-to-dqn-option';
import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CostCenterEditDialogData} from './models/cost-center-edit-dialog-data.model';
import {CostCenterEditDialogOutputData} from './models/cost-center-edit-dialog-output-data.model';

@Component({
  selector: 'app-cost-center-edit-dialog',
  templateUrl: './cost-center-edit-dialog.component.html',
  styleUrls: ['./cost-center-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CostCenterEditDialogComponent implements OnInit {
  private readonly matDialogRef = inject(MatDialogRef<CostCenterEditDialogComponent, CostCenterEditDialogOutputData>);
  private readonly data: CostCenterEditDialogData = inject(MAT_DIALOG_DATA);

  costCenterForm = new FormGroup({
    costCenter: new FormControl<number>(null),
  });

  get costCenterControl() {
    return this.costCenterForm.get('costCenter');
  }

  get productName() {
    return this.data.productName;
  }

  get activeCostCenterId() {
    return this.data.activeCostCenterId ?? this.defaultCostCenterId;
  }

  get availableCostCentersAsDqnOptions() {
    return this.data.availableCostCenters.map(convertCostCenterToDqnOption);
  }

  private get defaultCostCenterId() {
    return this.data.defaultCostCenterId;
  }

  ngOnInit() {
    this.costCenterControl.patchValue(this.activeCostCenterId);
  }

  save() {
    const updatedCostCenterId = (this.defaultCostCenterId === this.costCenterControl.value)
      ? null
      : this.costCenterControl.value;
    this.matDialogRef.close({costCenterId: updatedCostCenterId} as CostCenterEditDialogOutputData);
  }

  cancel() {
    this.matDialogRef.close(null);
  }
}
